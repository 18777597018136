import project1 from "../../images/service_img-1.png";
import project2 from "../../images/service_img-2.png";
import project3 from "../../images/service_img-3.png";
import project4 from "../../images/service_img-4.png";
import project5 from "../../images/service_img-5.png";
import project6 from "../../images/service_img-6.png";

export const ProjectData = [
  {
    id: 1,
    name: "Painting Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project1,
  },
  {
    id: 2,
    name: "Plumbing Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project2,
  },
  {
    id: 3,
    name: "Electrical Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project3,
  },
  {
    id: 4,
    name: "Home Renovation",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project4,
  },
  {
    id: 5,
    name: "Cleaning Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project5,
  },
  {
    id: 6,
    name: "Carpenter Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy.",
    btn: "Read More",
    img: project6,
  },
];
