import blog1 from "../../images/lastest_blog_1.png";
import blog2 from "../../images/lastest_blog_2.png";
import blog3 from "../../images/lastest_blog_3.png";
import blog4 from "../../images/lastest_blog_4.png"
import blog5 from "../../images/lastest_blog_5.png"

export const BlogData = [
  {
    id: 1,
    name: "When have a problem with cracked pipes",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog1
  },
  {
    id: 2,
    name: "The most common painting mistakes",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog2
  },
  {
    id: 3,
    name: "Dangers of having high water pressure",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog3
  },
  {
    id: 4,
    name: "The most common painting mistakes",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog4
  },
  {
    id: 5,
    name: "Dangers of having high water pressure",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog5
  },
  {
    id: 6,
    name: "When have a problem with cracked pipes",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting & industry. Lorem Ipsum has...",
    btn: "Continue More",
    img : blog3
  },
];
