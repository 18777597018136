import service1 from "../../images/service_img-1.png";
import service2 from "../../images/service_img-2.png";
import service3 from "../../images/service_img-3.png";
import service4 from "../../images/service_img-4.png";
import service5 from "../../images/service_img-5.png";
import service6 from "../../images/service_img-6.png";

export const ServiceData = [
  {
    id: 1,
    name: "Painting Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service1,
    btn: "Read More",
  },
  {
    id: 2,
    name: "Plumbing Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service2,
    btn: "Read More",
  },
  {
    id: 3,
    name: "Electrical Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service3,
    btn: "Read More",
  },
  {
    id: 4,
    name: "Home Renovation",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service4,
    btn: "Read More",
  },
  {
    id: 5,
    name: "Cleaning Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service5,
    btn: "Read More",
  },
  {
    id: 6,
    name: "Carpenter Services",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    img: service6,
    btn: "Read More",
  },
];
